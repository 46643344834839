export const sharedColors = {
    main: "#1f456e",
    subMain: "#2c3e4c",
    hoverColor: "#fc4c4e" // was #f89ca6; was rgb(255, 130, 92)
};
export const sharedSizes = {
    logo: {
        width: "200px",
        height: "64px" //was 64px
    }
};
