var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../redux/axiosInstance';
import { genGetInvitationContentUrl } from 'eventesize-shared';
// initialState should be empty, temp for development
const initialState = {
    title: "",
    freeText: "",
    info: "",
    location: "",
};
// Async thunk for API call (example for fetching arrival data)
export const getInvitationContent = createAsyncThunk('invitationContent/getInvitationContent', (params, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield axiosInstance.get(genGetInvitationContentUrl(params.eventId));
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
}));
const invitationContentSlice = createSlice({
    name: 'invitationContent',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getInvitationContent.fulfilled, (state, action) => {
            const payload = action.payload;
            state.title = payload.title;
            state.freeText = payload.freeText;
            state.info = payload.info;
            state.location = payload.location;
        });
    },
});
export default invitationContentSlice.reducer;
