var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { genSaveArrivalStatusUrl } from 'eventesize-shared';
import axiosInstance from '../../../redux/axiosInstance';
const initialState = {
    peopleArriving: 1,
};
// Async thunk for API call (example for fetching arrival data)
export const saveArrivalsStatus = createAsyncThunk('arrivals/saveArrivalsStatus', (params, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        thunkAPI.dispatch(params.isApproved ? approveArrival() : declineArrival());
        const state = thunkAPI.getState();
        const arrivalsStatus = { arrivalsNumber: params.isApproved ? state.arrivals.peopleArriving : 0 };
        const response = yield axiosInstance.post(genSaveArrivalStatusUrl(params.eventId, params.guestId), arrivalsStatus);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
}));
const arrivalsSlice = createSlice({
    name: 'arrivals',
    initialState,
    reducers: {
        incrementPeople: (state) => {
            state.peopleArriving += 1;
        },
        decrementPeople: (state) => {
            if (state.peopleArriving > 1) {
                state.peopleArriving -= 1;
            }
        },
        approveArrival: (state) => {
            state.isApproved = true;
        },
        declineArrival: (state) => {
            state.isApproved = false;
            state.sentPeopleArriving = 0;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveArrivalsStatus.fulfilled, (state) => {
            state.sentPeopleArriving = state.peopleArriving;
        });
    },
});
export const { incrementPeople, decrementPeople, approveArrival, declineArrival } = arrivalsSlice.actions;
export default arrivalsSlice.reducer;
