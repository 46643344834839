import { jsx as _jsx } from "react/jsx-runtime";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import "antd/dist/antd.min.css";
import Router from "./router";
import { Provider } from "react-redux";
import store from "./redux/store";
const App = () => (_jsx(Provider, Object.assign({ store: store }, { children: _jsx(BrowserRouter, { children: _jsx(Router, {}) }) })));
const container = document.getElementById('root');
const root = createRoot(container);
root.render(_jsx(App, {}));
