import axios from 'axios';
// Create an instance of Axios with base configurations
const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_HOST}/api/v1`,
    // baseURL: 'http://localhost:3001/api/v1',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
        // Add any default headers here, e.g., Authorization tokens
    }
});
export default axiosInstance;
