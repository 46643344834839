import { configureStore } from '@reduxjs/toolkit';
import arrivalsReducer from '../pages/ArrivalsStatusForm/redux/arrivalsSlice';
import appReducer from './appSlice';
import invitationContentReducer from '../pages/ArrivalsStatusForm/redux/invitationContentSlice';
// Create and configure the Redux store
const store = configureStore({
    reducer: {
        arrivals: arrivalsReducer,
        invitationContent: invitationContentReducer,
        app: appReducer,
    },
});
export default store;
