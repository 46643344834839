import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Styles } from "../styles/styles";
import routes from "./config";
const lazyRoutes = routes.map(routeItem => (Object.assign(Object.assign({}, routeItem), { element: lazy(() => import(`../pages/${routeItem.component}`)) })));
const Router = () => {
    return (_jsxs(Suspense, Object.assign({ fallback: null }, { children: [_jsx(Styles, {}), _jsx(Routes, { children: lazyRoutes.map((routeItem) => {
                    return (_jsx(Route, { path: routeItem.path, element: _jsx(routeItem.element, {}) }, routeItem.component));
                }) })] })));
};
export default Router;
