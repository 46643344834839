import { createSlice } from '@reduxjs/toolkit';
import { saveArrivalsStatus } from '../pages/ArrivalsStatusForm/redux/arrivalsSlice';
import { getInvitationContent } from '../pages/ArrivalsStatusForm/redux/invitationContentSlice';
const initialState = {
    loading: true,
};
const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            //saveArrivalsStatus
            .addCase(saveArrivalsStatus.pending, (state) => {
            state.loading = true;
            state.error = undefined;
        })
            .addCase(saveArrivalsStatus.fulfilled, (state) => {
            state.loading = false;
        })
            .addCase(saveArrivalsStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
            //getInvitationContent
            .addCase(getInvitationContent.pending, (state) => {
            state.loading = true;
            state.error = undefined;
        })
            .addCase(getInvitationContent.fulfilled, (state) => {
            state.loading = false;
        })
            .addCase(getInvitationContent.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});
export const { setError } = appSlice.actions;
export default appSlice.reducer;
