const routes = [
    {
        path: "/",
        component: "Home",
    },
    {
        path: "/arrivals-status/:eventId/:guestId",
        component: "ArrivalsStatusForm",
    },
];
export default routes;
